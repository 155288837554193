<template>
  <div>
    <v-dialog v-model="dialog">
      <v-form ref="form">
        <h1>ポイント提携施設予約</h1>
        <div>
          <v-text-field
            :label="`利用${ $route.params.type || type }数`"
            v-model="NumberOfUsage"
            :rules="rules.points"
          ></v-text-field>
        </div>
        <div class="d-flex justify-end">
          <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
            {{ $t('task_29786.close') }}
          </v-btn>
          <!-- <v-btn v-if="valid === true" target="_blank" class="t-btn--prm" @click="save" :to="'/contract/reservation_point_affiliated_facility/' + this.id + '/' + this.type+'/'+this.NumberOfUsage" > 次へ </v-btn> -->
          <v-btn target="_blank" class="t-btn--prm" @click="save" > 次へ </v-btn>
        </div>
       </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { checkNumber } from '@/utils/validate';
export default {
  name: 'PointAffiliatedFacilityReservation',
  data() {
    return {
      NumberOfUsage:null,
      points: null,
      rules: {
        points: [
          v => !!v || this.$t('rules.requiredField'),
          v => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          v =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32'),
        ],
      },
    };
  },
  props: {
    visible: Boolean,
    clientId: Number,
    id: Number,
    type: String,
  },
  mounted(){
    this.setPointInput(null);
  },
  computed: {
    ...mapGetters(['getPointInput']),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      },
    },
  },
  methods: {
    ...mapActions(['']),
    ...mapMutations([
      'setPointInput',
    ]),
    save() {
      if (this.$refs.form.validate()) {
        const routeData = this.$router.resolve({path: `/contract/reservation_point_affiliated_facility/${this.id}/${this.type}/${this.NumberOfUsage}?clientId=${this.clientId}`});
        window.open(routeData.href, '_blank');
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit('close');
      this.$refs.form.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.boder_padding {
  padding: 10px 0 10px 20px;
}
.boder_gray_padding {
  border-bottom: 1px solid gray;
  padding: 10px 0 10px 20px;
}
.color {
  color: #3ac1ee;
}
::v-deep {
  .selectMembership .v-select__selection {
    color: red;
  }
  .v-dialog {
    width: 400px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
.points-style {
  ::v-deep {
    .v-label {
      font-size: 12px !important;
    }
  }
}
.title {
  color: #212121;
  font-weight: bold;
  font-size: 28px !important;
}
</style>
