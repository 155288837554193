<template>
  <v-dialog @click:outside="closeDialog" v-model="dialog">
    <v-form ref="form" v-if="dialog">
      <h1>ギフトチケット購入</h1>
      <div class="mt-6">
        <h6>所持ポイント</h6>
        <p class="mt-1">{{ point }}pt</p>
      </div>
      <div>
        <template>
          <v-select
            :items="getPointExchangeProductList"
            v-model="productId"
            item-value="id"
            :rules="rules.required"
            item-text="name"
            :label="labelTickets"
          >
            <template v-slot:item="{item}">
              <PointExchangeProductItem :item="item" />
            </template>
          </v-select>
        </template>
      </div>
      <div>
        <v-select
          v-model="quantity"
          :rules="rules.required"
          :items="numbers"
          :label="labelNumber"
        ></v-select>
      </div>
      <div>
        <point-exchange-name-select v-model="orderBy" :clientId="clientId" label="申込者" />
      </div>
      <div>
        <h6>合計ポイント</h6>
        <p class="mt-1">{{ totalPoints }}pt</p>
      </div>
      <div class="d-flex justify-end">
        <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
          {{ $t('common.close') }}
        </v-btn>
        <v-btn class="t-btn--prm" @click="save" :disabled="buying">
          {{ $t('buttons.exchange') }}
        </v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import PointExchangeProductItem from '@/components/select/PointExchangeProductItem'
import PointExchangeNameSelect from '../select/PointExchangeNameSelect.vue';

export default {
  name: 'PointExchangeOrderDialog',
  components: { PointExchangeProductItem, PointExchangeNameSelect },
  data() {
    return {
      labelTickets: 'チケット種類',
      labelNumber: '枚数',
      rules: {
        required: [(v) => !!v || '必須項目です。'],
      },
      productId: null,
      quantity: null,
      orderBy: null,
      buying: false
    };
  },
  props: {
    value: Boolean,
    point: Number,
    clientId: Number,
    contractId: Number,
    priceType: String,
  },
  mounted() {
    this.pointExchangeProductList();
  },
  computed: {
    ...mapGetters([
      'getPointExchangeProductList',
    ]),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    totalPoints () {
      if (this.productId) {
        const qty = this.quantity ? this.quantity : 1;
        const point = this.getPointExchangeProductList.filter(
          (e) => e.id === this.productId,
        )[0][this.priceType];
        return point * qty;
      }
      return null
    },

    numbers() {
      var arr = [];
      for (let i = 1; i <= 100; i++) {
        arr.push(i);
      }
      return arr;
    },
  },
  methods: {
    ...mapActions([
      'ticketsBuy',
      'pointExchangeProductList',
    ]),
    async save() {
      if (this.$refs.form.validate()) {
        this.buying = true;
        try {
          await this.ticketsBuy({
            clientId: this.clientId,
            contractId: this.contractId,
            quantity: this.quantity,
            productId: this.productId,
            memberUserId: this.orderBy.memberUserId,
            name: this.orderBy.name
          });
        } finally {
          this.buying = false
        }
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.boder_padding {
  padding: 10px 0 10px 20px;
}
.boder_gray_padding {
  border-bottom: 1px solid gray;
  padding: 10px 0 10px 20px;
}
h1 {
  color: #212121;
  font-size: 28px;
  font-weight: bold;
}
h6 {
  font-size: 10px;
  color: #000;
  font-weight: 600;
}
p {
  font-size: 12px;
  color: #000;
  font-weight: 500;
}
::v-deep {
  .v-dialog {
    width: 294px;
    background-color: white;
    padding: 20px;
    border-radius: 9px;
    .v-label {
      font-size: 10px;
      font-weight: 600;
      color: #000 !important;
    }
  }
  .v-text-field {
    margin-top: 0px !important;
  }
  .v-text-field--is-booted {
    width: 190px;
  }
  .v-list-item__title,
  .v-select__selection--comma {
    font-size: 12px !important;
    font-weight: 500;
    color: #000;
  }
  .v-icon__svg {
    color: #333333 !important;
  }
  .theme--light.v-input,
  .theme--light.v-select .v-select__selections {
    color: #000 !important;
  }
  .v-btn {
    font-size: 12px !important;
    height: 32px !important;
    i {
      font-size: 14px;
    }
  }
  .t-btn--red-dark {
    width: 72px !important;
  }
  .t-btn--prm {
    width: 68px !important;
  }
}
</style>
