<template>
  <div>
    <div class="d-flex align-center">
      <div v-if="memberHidden" class="color-red mr-2">非公開</div>
      <div>{{ item.name }}</div>
    </div>
    <div v-if="saleEndSoon" class="color-red">
      交換期限: {{ saleEndAt | formatDateTime }}
    </div>
  </div>
</template>

<script>
import {giftTicketSaleEndSoon} from '@/utils/dateUtil'

export default {
  props: {
    item: Object,
  },
  computed: {
    memberHidden() {
      return !this.item.showMember
    },
    saleEndAt() {
      return this.item.saleEndAt
    },
    saleEndSoon() {
      return giftTicketSaleEndSoon(this.saleEndAt);
    }
  }
}
</script>