<template>
  <v-combobox
    :items="items"
    item-text="name"
    :value="value"
    @change="onChange"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  props: {
    value: Object, // { name:  string; memberUserId: number | null; }
    clientId: Number
  },
  data() {
    return {
      client: null,
    }
  },
  computed: {
    isCompany() {
      return this.client?.isCompany
    },
    items() {
      const subMembers = this.client?.subMembers ?? [];
      const memberUsers = subMembers.filter(sm => sm.memberUser).map(sm => ({
        memberUserId: sm.memberUser.id,
        name: sm.name
      }))
      if (this.client?.memberUser) {
        memberUsers.unshift({
          memberUserId: this.client.memberUser.id,
          name: this.client.name
        })
      }
      return memberUsers
    }
  },
  watch: {
    clientId: {
      immediate: true,
      handler() {
        this.loadClient();
      }
    }
  },
  methods: {
    async loadClient() {
      if (this.clientId) {
        this.client = await this.$store.dispatch('getClientMemberInfo', this.clientId)
        await this.$nextTick();
        if (this.items?.length){
          this.$emit('input', this.items[0])
        }
      } else {
        this.client = null;
      }
    },
    onChange(v) {
      if (typeof v === 'string') {
        // user created item.
        this.$emit('input', {
          name: v,
          memberUserId: null
        })
      } else if (typeof v === 'object') {
        this.$emit('input', v)
      } else {
        // impossible, hopefully...
        console.error('unknown input type', v);
      }
    },
    selectValueCompare (a,b) {
      return a && b && a.memberUserId === b.memberUserId
    }
  }
}
</script>

<style>

</style>